<template>
  <div>
    <div class="page-content border-bottom">
      <!-- 顶部搜索框 -->
      <el-form :inline="true" :model="searchInfo" class="demo-form-inline">
        <el-form-item style="float: right;">
          <el-button icon="el-icon-plus" type="primary" @click="onAddUser">新增</el-button>
        </el-form-item>
      </el-form>
    </div>
    <!-- 表格展示区域 -->
    <div class="page-content margin-top">
      <!-- 表格组件 -->
      <el-table
          :data="tableData"
          stripe
          style="width: 100%"
          :header-cell-style="{ background: '#eef1f6', color: '#606266' }"
      >
        <!-- 循环展示表格 -->
        <el-table-column
            v-for="tab in tableTitle"
            :key="tab.code"
            :label="tab.name"
            show-overflow-tooltip
            :width="tab.width"
            align="center">
          <template v-slot:default="scope">
            <span>{{ scope.row[tab.code] }} </span>
          </template>
        </el-table-column>
        <!-- 操作 -->
        <el-table-column label="操作" width="200">
          <template v-slot:default="scope">
            <span @click="updateRow(scope.row)" class="update-btn">修改</span>
            <span v-if="scope.row.id !== 1" @click="deleteRow(scope.row)" class="delete-btn">删除</span>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页组件 -->
      <div>
        <Pagination
            :total="dataTotal"
            :page="searchInfo.page"
            :limit="searchInfo.limit"
            @pagination="onChangePages"
        />
      </div>
    </div>

    <!-- 新增账号弹框 -->
    <el-dialog
        :title="isUpdate ? '修改' : '新增'"
        :visible.sync="dialogVisible"
        top="5vh"
        width="400px">
      <el-form
          ref="form"
          class="dialog-form"
          :model="accountEdit"
          label-width="80px">
        <el-form-item label="用户名">
          <el-input
              v-model="accountEdit.name"
              placeholder="请输入用户名"
          ></el-input>
        </el-form-item>
        <el-form-item label="账号">
          <el-input
              v-model="accountEdit.account"
              placeholder="请输入账号"
              :disabled="isUpdate"
          ></el-input>
        </el-form-item>
        <el-form-item label="密码">
          <el-input
              v-model="accountEdit.password"
              placeholder="请输入密码"
              type="password"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取消</el-button>
        <el-button type="primary" @click="onSubmitDialog">确定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import Pagination from '@/components/Pagination'
import request from "@/api/request";
import {Encrypt} from "@/utils/crypto";

export default {
  components: {
    Pagination
  },
  data() {
    return {
      // 查询数据
      searchInfo: {
        page: 1,
        size: 10,
      },
      // 弹框表单信息
      accountEdit: {
        account: '',
        password: '',
        name: ''
      },
      // 表格标题数组
      tableTitle: [
        {name: '编号', code: 'id', width: '200'},
        {name: '用户名', code: 'name', width: ''},
        {name: '账号', code: 'account', width: ''}
      ],
      // 表格数据
      tableData: [],
      // 数据总数
      dataTotal: 0,
      // 标记是否新增
      isUpdate: false,
      // 是否展示弹框
      dialogVisible: false
    }
  },
  mounted() {
    // 查询数据
    this.onSearch()
  },
  methods: {
    // 上传图片触发
    handleChangeImg(imgList) {
      console.log(imgList)
    },
    // 查询
    onSearch() {
      this.searchInfo.page = 1
      this.onGetDataList()
    },
    // 重置
    onReset() {
      this.searchInfo.time = ''
      this.searchInfo.address = ''
      this.onSearch()
    },
    // 新增方法
    onAddUser() {
      this.accountEdit = {};
      this.isUpdate = false
      this.dialogVisible = true
    },
    // 更新数据
    updateRow(row) {
      this.isUpdate = true
      this.dialogVisible = true
      this.accountEdit = row;
      this.accountEdit.password = null;
    },
    // 删除数据
    deleteRow(row) {
      request({
        url: "/account-info/delete/" + row.id,
        method: "delete"
      })
          .then((res) => {
            if (res.data.code === 200) {
              this.msgSuccess(res.data.message)
              this.onSearch();
            } else {
              this.msgError(res.data.message)
            }
          })
          .catch((error) => {
            console.error(error)
          });
    },
    // 分页方法
    onChangePages(pageInfo) {
      const {page, limit} = pageInfo
      this.searchInfo.page = page
      this.searchInfo.size = limit
      this.onGetDataList()
    },
    // 核心查询数据方法
    onGetDataList() {
      // 查询数据方法
      request({
        url: "/account-info/search/" + this.searchInfo.page + "/" + this.searchInfo.size,
        method: "get"
      })
          .then((res) => {
            this.tableData = res.data.data.records;
            this.dataTotal = res.data.data.total;
          })
          .catch((error) => {
            console.error(error)
          });
    },
    // 弹框确认方法
    onSubmitDialog() {
      if (this.accountEdit.password) {
        this.accountEdit.password = Encrypt(this.accountEdit.password);
      }
      if (!this.isUpdate) {
        if (!this.accountEdit.account) {
          this.msgError('请输入账号')
          return
        }
        if (!this.accountEdit.password) {
          this.msgError('请输入密码')
          return
        }
        request({
          url: "/account-info/add",
          method: "post",
          data: JSON.stringify(this.accountEdit)
        })
            .then((res) => {
              if (res.data.code === 200) {
                this.msgSuccess(res.data.message)
                this.onSearch();
              } else {
                this.msgError(res.data.message)
              }
            })
            .catch((error) => {
              console.error(error)
            });
      } else {
        request({
          url: "/account-info/update",
          method: "put",
          data: JSON.stringify(this.accountEdit)
        })
            .then((res) => {
              if (res.data.code === 200) {
                this.msgSuccess(res.data.message)
                this.onSearch();
              } else {
                this.msgError(res.data.message)
              }
            })
            .catch((error) => {
              console.error(error)
            });
      }
      this.dialogVisible = false;
    },
  },
}
</script>

<style lang="less" scoped>
.el-form-item {
  margin-bottom: 4px !important;
}

.border-bottom {
  border-bottom: 1px solid #e2e2e4;
}

.margin-top {
  margin-top: -15px;
}

.update-btn {
  cursor: pointer;
  color: #409eff;
}

.delete-btn {
  cursor: pointer;
  color: #f56c6c;
  margin-left: 20px;
}

.dialog-form {
  display: flex;
  flex-direction: column;
  gap: 15px;
}
</style>